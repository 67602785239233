


































































































































@import '@design';

.menu-section {
  position: relative;
  margin-bottom: 2.5em;
  &.subsection {
    margin-top: 50px;
    margin-bottom: 0;
  }
  &:not(.subsection):not(.callout) {
    padding-top: 0.5em;
  }

  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
  &.callout {
    $gutter: 1.3em;

    padding-top: $gutter;
    padding-bottom: $gutter;
    margin: $gutter;
    border: 2px solid $color-treps-blue;
    border-radius: 10px;
    &.has-title {
      padding-top: $gutter * 2;

      .menu-section--items {
        margin: 1em 0 0;
      }
    }
  }
} // .menu-section

.menu-section--title {
  margin-bottom: 0.3em;
  font-size: 1.8em;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 0.071em !important;
  &.placeholder {
    position: relative;
    z-index: 10;
    font-style: italic;
    color: darken(white, 25%) !important;
  }
}

.menu-section--subtitle .resto-editable {
  font-size: 0.95em;
  font-weight: 300 !important;
  color: #707070;
  letter-spacing: 0.015em;
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}
